
import css from './State5.module.css'
import {motion} from 'framer-motion'

const State5 = () =>{
    return(
<>
<motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:5,duration:1}} className={css.container}>
    <div className={css.block1Container}>
        <div className={css.glitchWrapper}>
                        <span data-text="Full statistic" className={`${css.block2Header} ${css.glitch}`}>Full statistic</span>
                    </div>
        <div className={css.column}>
            <span className={css.columnHeader}>total tokens:</span>
            <span className={css.columnCount}>20 000 000</span>
        </div>
        <div className={css.column}>
            <span className={css.columnHeader}>total tokens:</span>
            <span className={css.columnCount}>20 000 000</span>
        </div>
        <div className={css.column}>
            <span className={css.columnHeader}>total tokens:</span>
            <span className={css.columnCount}>20 000 000</span>
        </div>
        <div className={css.column}>
            <span className={css.columnHeader}>total tokens:</span>
            <span className={css.columnCount}>20 000 000</span>
        </div>
    </div>
    <div className={css.block2}>
        <div className={css.block2Container}>
            <div className={css.glitchWrapper}>
                        <span data-text="Last great news" className={`${css.block2Header} ${css.glitch}`}>Last great news</span>
                    </div>
            <p className={css.block2descr}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt 
            mollit anim id est laborum.
            </p>
            <span className={css.block2Domine}>domain.com</span>
        </div>
        <p className={css.block2Descr2}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt 
            mollit anim id est laborum.
        </p>
        <div className={css.dimainBlock}>
            <span className={css.dimainBlockDomain}>domain.com</span>
            <span className={css.dimainBlockDomain}>domain.com</span>
            <span className={css.dimainBlockDomain}>domain.com</span>
            <span className={css.dimainBlockDomain}>domain.com</span>
        </div>
    </div>
</motion.div>

</>
    )
}

export default State5