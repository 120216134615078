import css from './ModalByToken.module.css'
import close from '../../img/Close1.png'
import mobx from '../../store/mobx'
import {observer} from 'mobx-react-lite'
import {motion} from 'framer-motion'
import cookie1 from '../../img/cookie1.png'
import iconModalCard from '../../img/iconModalCard.png'

const ModalByToken = observer(() => {
    if(mobx.modalByToken){
        return ( 
            <>
                <motion.section onClick={()=>mobx.setModalByToken(false)}  initial={{opacity:0}} whileInView={{opacity:1}}  className={css.container}>     
                <div className={css.modalWind} onClick={e => e.stopPropagation()} >
                    <img src={cookie1} className={css.cookie}/>
                    <h2 className={css.header}>buy token</h2>
                    <img  src={close} onClick={()=>mobx.setModalByToken(false)} className={css.close}/>
                    <div className={css.cardContainer}>
                        <a href={mobx.binanceArrow[mobx.binanceID].tnl1} className={css.card}>
                        <img className={css.icon} src={`assets/img/${mobx.binanceArrow[mobx.binanceID].tnl1ICON}`} />
                            <span className={css.cardHeader}>{mobx.binanceArrow[mobx.binanceID].tnl1IName}</span>
                        </a>
                        <a href={mobx.binanceArrow[mobx.binanceID].tnl2} className={css.card}>
                        <img className={css.icon} src={`assets/img/${mobx.binanceArrow[mobx.binanceID].tnl2ICON}`} />
                            <span className={css.cardHeader}>{mobx.binanceArrow[mobx.binanceID].tnl2IName}</span>
                        </a>
                        <a href={mobx.binanceArrow[mobx.binanceID].tnl3} className={css.card}>
                        <img className={css.icon} src={`assets/img/${mobx.binanceArrow[mobx.binanceID].tnl3ICON}`} />
                            <span className={css.cardHeader}>{mobx.binanceArrow[mobx.binanceID].tnl3IName}</span>
                        </a>
                        <a href={mobx.binanceArrow[mobx.binanceID].tnl4} className={css.card}>
                        <img className={css.icon} src={`assets/img/${mobx.binanceArrow[mobx.binanceID].tnl4ICON}`} />
                            <span className={css.cardHeader}>{mobx.binanceArrow[mobx.binanceID].tnl4IName}</span>
                        </a>
                        <a href={mobx.binanceArrow[mobx.binanceID].tnl5} className={css.card}>
                        <img className={css.icon} src={`assets/img/${mobx.binanceArrow[mobx.binanceID].tnl5ICON}`} />
                            <span className={css.cardHeader}>{mobx.binanceArrow[mobx.binanceID].tnl5IName}</span>
                        </a>
                        <a href={mobx.binanceArrow[mobx.binanceID].tnl6} className={css.card}>
                        <img className={css.icon} src={`assets/img/${mobx.binanceArrow[mobx.binanceID].tnl6ICON}`} />
                            <span className={css.cardHeader}>{mobx.binanceArrow[mobx.binanceID].tnl6IName}</span>
                        </a>
                        <a href={mobx.binanceArrow[mobx.binanceID].tnl7} className={css.card}>
                        <img className={css.icon} src={`assets/img/${mobx.binanceArrow[mobx.binanceID].tnl7ICON}`} />
                            <span className={css.cardHeader}>{mobx.binanceArrow[mobx.binanceID].tnl7IName}</span>
                        </a>
                        <a href={mobx.binanceArrow[mobx.binanceID].tnl8} className={css.card}>
                        <img className={css.icon} src={`assets/img/${mobx.binanceArrow[mobx.binanceID].tnl8ICON}`} />
                            <span className={css.cardHeader}>{mobx.binanceArrow[mobx.binanceID].tnl8IName}</span>
                        </a>
                        
                    </div>
                </div>
                
                </motion.section>
            
            </>
                 );
    }
   
})
 
export default ModalByToken;