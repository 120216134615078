import { useEffect } from 'react'
import css from './State3.module.css'
import {motion} from 'framer-motion'
import mobx from '../../../store/mobx'

const State3 = () =>{
    
    useEffect(()=>{
        mobx.setDollarVisible(true)

        return ()=>{
            mobx.setDollarVisible(false)
        }

    },[])
    return(
<>

<motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:5,duration:1}} className={css.block2Container}>
                        <span data-text="Congrats, you are eligible! Your tokens amount is 1971 token" className={`${css.block2Header} `}>Congrats, you are eligible! Your tokens amount is 1971 token</span>
    <a className={css.button} onClick={()=>mobx.setDollarVisible(!mobx.dollarVisibe)}> claim</a>
   
</motion.div>
</>
    )
}

export default State3