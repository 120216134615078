import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Modal from "../../components/Modal/Modal";


const Web = ({height,display}) => {
    return ( 
<div style={{height:height,display:display}}>
    <Hero/>
</div>
     );
}
 
export {Web};