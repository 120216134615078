import css from './Hero.module.css'
import binance from '../../img/binance.png'
import triangle from '../../img/triangle.png'
import topCookie from '../../img/topCookie.png'
import cookie1 from '../../img/cookie1.png'
import cookie2 from '../../img/cookie2.png'
import cookie3 from '../../img/cookie3.png'
import cookie4 from '../../img/cookie4.png'
import cookie5 from '../../img/cookie5.png'
import cookie6 from '../../img/cookie6.png'
import cookie7 from '../../img/cookie7.png'
import cookie8 from '../../img/cookie8.png'
import cookie9 from '../../img/cookie9.png'
import cookie10 from '../../img/cookie10.png'
import cookie11 from '../../img/cookie11.png'
import cookie13 from '../../img/cookie13.png'
import cookie14 from '../../img/cookie14.png'
import cookie15 from '../../img/cookie15.png'
import arrow from '../../img/arrow.png'
import cookies from '../../img/cookies.png'
import Footer from '../Footer/Footer'
import Marquee from "react-fast-marquee";
import mobx from '../../store/mobx'
import {motion} from 'framer-motion'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import diagramm1 from '../../img/diagramm/1.png'
import diagramm2 from '../../img/diagramm/2.png'
import diagramm3 from '../../img/diagramm/3.png'
import diagramm4 from '../../img/diagramm/4.png'
import diagramm5 from '../../img/diagramm/5.png'
import diagramm1m from '../../img/diagramm/1m.png'
import diagramm2m from '../../img/diagramm/2m.png'
import diagramm3m from '../../img/diagramm/3m.png'
import diagramm4m from '../../img/diagramm/4m.png'
import diagramm5m from '../../img/diagramm/5m.png'
import binanceModal from '../../img/binanceModal.png'
import {observer} from 'mobx-react-lite'

import jQuery from 'jquery'



const Hero =  observer(() => {

    const d1Ref = useRef()
    const d2Ref = useRef()
    const d3Ref = useRef()
    const d4Ref = useRef()
    const d5Ref = useRef()
    const block1Ref = useRef()
    const block2Ref = useRef()
    const block3Ref = useRef()
    const block4Ref = useRef()
    const block5Ref = useRef()
    const arrow1Ref = useRef()
    const arrow2Ref = useRef()
    const arrow3Ref = useRef()
    const arrow4Ref = useRef()
    const arrow5Ref = useRef()
    const cookie1Ref = useRef()
    const cookie2Ref = useRef()
    const cookie3Ref = useRef()
    const cookie4Ref = useRef()
    const cookie5Ref = useRef()
    const cookie6Ref = useRef()
    const header2Ref = useRef()
    const totalRef = useRef()
    const [headerOpacity,setHeaderOpacity]= useState(0)
    const [faqState1, setFaqState1] = useState(true)
    const [faqState2, setFaqState2] = useState(true)
    const [faqState3, setFaqState3] = useState(true)
    const [faqState4, setFaqState4] = useState(true)
    const [faqHeight,setFaqHeight] = useState("5vh")
    const [block2Delay, setBlock2Delay] = useState(2)
    const faqRef1 = useRef()
    const faqRef2 = useRef()
    const faqRef3 = useRef()
    const faqRef4 = useRef()
    const scrollHandler = (event) => {
        if (200 <= window.scrollY && window.scrollY <= 230) {
              gsap.to(d1Ref.current,{opacity:1,delay:.1})
              gsap.to(block5Ref.current,{opacity:1,delay:.1})
              gsap.to(arrow5Ref.current,{opacity:1,delay:.1})
              gsap.to(d2Ref.current,{opacity:1,delay:.2})
              gsap.to(block4Ref.current,{opacity:1,delay:.2})
              gsap.to(arrow4Ref.current,{opacity:1,delay:.2})
              gsap.to(d3Ref.current,{opacity:1,delay:.3})
              gsap.to(block3Ref.current,{opacity:1,delay:.3})
              gsap.to(arrow3Ref.current,{opacity:1,delay:.3})
              gsap.to(d4Ref.current,{opacity:1,delay:.4})
              gsap.to(block2Ref.current,{opacity:1,delay:.4})
              gsap.to(arrow2Ref.current,{opacity:1,delay:.4})
              gsap.to(d5Ref.current,{opacity:1,delay:.5})
              gsap.to(block1Ref.current,{opacity:1,delay:.5})
              gsap.to(arrow1Ref.current,{opacity:1,delay:.5})
              gsap.to(header2Ref.current,{opacity:1,delay:1,duration:1})
              gsap.to(totalRef.current,{opacity:1,delay:1,duration:2})
              gsap.to(cookie3Ref.current,{opacity:1,x:20,delay:1.5,duration:1})
              gsap.to(cookie4Ref.current,{opacity:1,x:20,delay:1.5,duration:1})
              gsap.to(cookie5Ref.current,{opacity:1,x:20,delay:1.5,duration:1})
              gsap.to(cookie6Ref.current,{opacity:1,x:20,delay:1.5,duration:1})
              
        }
      };

    const faqClick = (state,setState,ref) =>{
        if(state){
            gsap.to(ref.current,{height:"auto"})
            setState(false)
        }else{
            setState(true)
            gsap.to(ref.current,{height:0})

        }
    }

    useEffect(() => {
        const width = window.screen.width
        if(width<=768){
            setFaqHeight("50vh")
            setBlock2Delay(0)
        }else if(width<=960){
            setFaqHeight("40vh")
        }if(width<=1024){
            setFaqHeight("20vh")
        }else if (width<=1440){
            setFaqHeight("20vh")
        }
        setTimeout(()=>{
            // Появление хедера
            setHeaderOpacity(1);
            (function($) {
                var s,
                spanizeLetters = {
                  settings: {
                    letters: $('.js-spanize'),
                  },
                  init: function() {
                    s = this.settings;
                    this.bindEvents();
                  },
                  bindEvents: function(){
                    s.letters.html(function (i, el) {
                      //spanizeLetters.joinChars();
                      var spanizer = $.trim(el).split("");
                      return '<span>' + spanizer.join('</span><span>') + '</span>';
                    });
                  },
                };
                spanizeLetters.init();
              })(jQuery);
        },[1000])

        // Функция для того,чтобы при размонтировании компонента удалялся обработчик события
        // const scrollHandler = (event) => {
        //   if (200 <= window.scrollY && window.scrollY <= 230) {
        //         gsap.to(d1Ref.current,{opacity:1,delay:.1})
        //         gsap.to(block5Ref.current,{opacity:1,delay:.1})
        //         gsap.to(arrow5Ref.current,{opacity:1,delay:.1})
        //         gsap.to(d2Ref.current,{opacity:1,delay:.2})
        //         gsap.to(block4Ref.current,{opacity:1,delay:.2})
        //         gsap.to(arrow4Ref.current,{opacity:1,delay:.2})
        //         gsap.to(d3Ref.current,{opacity:1,delay:.3})
        //         gsap.to(block3Ref.current,{opacity:1,delay:.3})
        //         gsap.to(arrow3Ref.current,{opacity:1,delay:.3})
        //         gsap.to(d4Ref.current,{opacity:1,delay:.4})
        //         gsap.to(block2Ref.current,{opacity:1,delay:.4})
        //         gsap.to(arrow2Ref.current,{opacity:1,delay:.4})
        //         gsap.to(d5Ref.current,{opacity:1,delay:.5})
        //         gsap.to(block1Ref.current,{opacity:1,delay:.5})
        //         gsap.to(arrow1Ref.current,{opacity:1,delay:.5})
        //         gsap.to(header2Ref.current,{opacity:1,delay:1,duration:1})
        //         gsap.to(totalRef.current,{opacity:1,delay:1,duration:2})
        //         gsap.to(cookie3Ref.current,{opacity:1,x:20,delay:1.5,duration:1})
        //         gsap.to(cookie4Ref.current,{opacity:1,x:20,delay:1.5,duration:1})
        //         gsap.to(cookie5Ref.current,{opacity:1,x:20,delay:1.5,duration:1})
        //         gsap.to(cookie6Ref.current,{opacity:1,x:20,delay:1.5,duration:1})
                
        //   }
        // };
    
        // // Добавление обработчика события
        // window.addEventListener('scroll', scrollHandler);
    
        // Функция, вызываемая при размонтировании компонента
        return () => {
          // Удаление обработчика события при размонтировании
        //   window.removeEventListener('scroll', scrollHandler);
        };
      }, []); 
    
// Добавление обработчика события
window.addEventListener('scroll', scrollHandler);
    return ( 
<>
    <section className={css.container}  >
        {mobx.binanceModalVisible&&<div className={css.binanceModalBacground} onClick={()=> mobx.setBinanceModalVisible(!mobx.binanceModalVisible)} ></div>}
        <Marquee loop="999" autoFill={true} className={css.marquee} onClick={()=> mobx.setBinanceModalVisible(false)}>
        <span>Бегущая строка</span> <span>Бегущая строка</span><span>Бегущая строка</span><span>Бегущая строка</span>
        <span>Бегущая строка</span> <span>Бегущая строка</span><span>Бегущая строка</span><span>Бегущая строка</span>
        <span>Бегущая строка</span> <span>Бегущая строка</span><span>Бегущая строка</span><span>Бегущая строка</span>
        <span>Бегущая строка</span> <span>Бегущая строка</span><span>Бегущая строка</span><span>Бегущая строка</span>
        <span>Бегущая строка</span> <span>Бегущая строка</span><span>Бегущая строка</span><span>Бегущая строка</span>
        </Marquee>
        <div className={css.loader}></div>
        
        <motion.img viewport={{once:true}} initial={{x:-100,opacity:0}} whileInView={{x:0,opacity:1}} transition={{duration:.7,delay:.5}} src={cookie9} className={css.cookie9}/> 
        <motion.img viewport={{once:true}} initial={{x:50,y:-50,opacity:0}} whileInView={{x:0,y:0,opacity:1}}  transition={{duration:.7,delay:.5}}  src={cookie10} className={css.cookie10}/> 
        {window.screen.width>1024?
        <motion.img viewport={{once:true}} initial={{x:-150,y:100,opacity:0}} whileInView={{x:0,y:0,opacity:1}}  transition={{duration:.7,delay:.5}}  src={cookie11} className={css.cookie11}/> :
        <motion.img viewport={{once:true}} initial={{x:-50,y:50,opacity:0}} whileInView={{x:0,y:0,opacity:1}}  transition={{duration:.7,delay:.5}}   src={cookie11} className={css.cookie11}/>        
        }

        <motion.img viewport={{once:true}} initial={{x:100,y:-50,opacity:0}} whileInView={{x:0,y:0,opacity:1}}  transition={{duration:.7,delay:.5}}  src={cookie13} className={css.cookie13}/> 
        {window.screen.width>768?
        <motion.img viewport={{once:true}} initial={{x:-50,y:-50,opacity:0}} whileInView={{x:0,y:0,opacity:1}}  transition={{duration:.7,delay:.5}}  src={cookie14} className={css.cookie14}/> :
        <motion.img viewport={{once:true}} initial={{x:50,y:50,opacity:0}} whileInView={{x:0,y:0,opacity:1}}  transition={{duration:.7,delay:.5}}  src={cookie14} className={css.cookie14}/>}
        <motion.img viewport={{once:true}} initial={{x:100,y:50,opacity:0}} whileInView={{x:0,y:0,opacity:1}}  transition={{duration:.7,delay:.5}}  src={cookie15} className={css.cookie15}/> 
        {/* Hero */}
        <div className={css.hero} >
        {mobx.binanceModalVisible&&<div className={css.binanceModalBacground} onClick={()=> mobx.setBinanceModalVisible(!mobx.binanceModalVisible)} ></div>}
            <section  className="mast" style={{opacity:headerOpacity}} onClick={()=> mobx.setBinanceModalVisible(false)}>
                <header className="mast__header">
                    <h1 className={`mast__titleh js-spanize`}>Header</h1> 
                    <p className="mast__texth js-spanize">Header2 Header2 Header2</p>
                </header>
            </section>
            <motion.p viewport={{once:true}} initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:2,duration:1}} className={css.formHeader}  onClick={()=> mobx.setBinanceModalVisible(false)}>Choose blockchain:</motion.p>
            <motion.div viewport={{once:true}} initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:2,duration:1}} className={css.input1Container} onClick={()=> mobx.setBinanceModalVisible(!mobx.binanceModalVisible)}>
                <motion.div viewport={{once:true}} initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:2,duration:1}} className={css.binanceContainer}  >
                    <img src={binance} className={css.binance}/>
                    <p className={css.binanceText} >{mobx.binanceArrow[mobx.binanceID].name}</p>


                </motion.div>
                <img src={triangle} className={css.triangle}/>
                {mobx.binanceModalVisible&&
                        <motion.div initial={{opacity:0}} whileInView={{opacity:1}} className={css.binanceModal}>
                        {mobx.binanceArrow.map((el)=>(
                            <>
                            <div key={el.id} className={css.arrowFlex} onClick={()=> mobx.setBinanceID(el.id)}>
                                <img src={binanceModal} className={css.binance} />
                                <p className={css.binanceModalText} >{el.name}</p>
                            </div>
                                
                            </>
                        ))}
                    </motion.div>
                    }
            </motion.div>
            <motion.div viewport={{once:true}} initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:2,duration:1}} className={css.tradeNow} onClick={()=> mobx.setModalByToken(true)}>
                TRADE NOW
            </motion.div>
            <motion.a href={mobx.binanceArrow[mobx.binanceID].block2Link} viewport={{once:true}} initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:2,duration:1}} className={css.input3Container}>
                {mobx.binanceArrow[mobx.binanceID].block2text}
            </motion.a>
            
        </div>  {/* Hero */}
        {/* Block2 */}
        <motion.div viewport={{once:true}} initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:block2Delay,duration:1}} className={css.block2Container}>
            <div className={css.diagammContainer}>
                <motion.h2 viewport={{once:true}}  initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1}} className={css.block2Header}>Header</motion.h2>
                <div className={css.diagrammDiv}>
                <div className={css.diagrammRelative}>
                        <p className={css.block2Header2} ref={header2Ref}>total supple</p>
                    <div className={css.glitchWrapper} ref={totalRef}>
                        <span data-text="35.000.000.000" className={`${css.blockCount} ${css.glitch}`}>35.000.000.000</span>
                    </div>
                {window.screen.width <=768 ?
                <>
                <img ref={d1Ref} src={diagramm1m} className={`${css.d1} d1`}/>
                <img ref={d2Ref} src={diagramm2m} className={`${css.d2} d2`}/>
                <img ref={d3Ref} src={diagramm3m} className={`${css.d3} d3`}/>
                <img ref={d4Ref} src={diagramm4m} className={`${css.d4} d4`}/>
                <img ref={d5Ref} src={diagramm5m} className={`${css.d5} d5`}/>
                </>
                :
                <>
                <img ref={d1Ref} src={diagramm1} className={`${css.d1} d1`}/>
                <img ref={d2Ref} src={diagramm2} className={`${css.d2} d2`}/>
                <img ref={d3Ref} src={diagramm3} className={`${css.d3} d3`}/>
                <img ref={d4Ref} src={diagramm4} className={`${css.d4} d4`}/>
                <img ref={d5Ref} src={diagramm5} className={`${css.d5} d5`}/>
                </>
                }

                    </div>
                </div>

                {/* <img src={diagramm} className={css.diagramm}/> */}
                
                <img src={cookie1} ref={cookie1Ref} id='cookie1' className={css.cookie1}/>
                <img src={cookie2} ref={cookie2Ref} id='cookie2' className={css.cookie2}/>
                <img src={cookie3} ref={cookie3Ref} id='cookie3' className={css.cookie3}/>
                <img src={cookie4} ref={cookie4Ref} id='cookie4' className={css.cookie4}/>
                <img src={cookie5} ref={cookie5Ref} id='cookie5' className={css.cookie5}/>
                <img src={cookie6} ref={cookie6Ref} id='cookie6' className={css.cookie6}/>    
                <img src={arrow} id='arrow1' ref={arrow1Ref} className={css.arrow1}/>
                <img src={arrow} id='arrow2' ref={arrow2Ref} className={css.arrow2}/>
                <img src={arrow} id='arrow3' ref={arrow3Ref} className={css.arrow3}/>
                <img src={arrow} id='arrow4' ref={arrow4Ref} className={css.arrow4}/>
                <img src={arrow} id='arrow5' ref={arrow5Ref} className={css.arrow5}/>
            </div>


            <div className={css.block2block1} ref={block1Ref}>
                <div>
                    <span className={css.block2BlockPercent}>40%</span>
                    <span className={css.block2blockName}>airdrop</span>
                </div>
                <span className={css.block2BlockDescription}>Amount: 30 000 000</span>
            </div> 
            <div className={css.block2block2} ref={block2Ref}>
                <span className={css.block2BlockPercent}>20%</span>
                <span className={css.block2blockName}>marketing and community</span>
                <span className={css.block2BlockDescription}>Amount: 30 000 000</span>
            </div>
            <div className={css.block2block3} ref={block3Ref}>
                <span className={css.block2BlockPercent}>5%</span>
                <span className={css.block2blockName}>team</span>
                <span className={css.block2BlockDescription}>Amount: 30 000 000</span>
            </div>
            <div className={css.block2block4} ref={block4Ref}>
                <span className={css.block2BlockPercent}>10%</span>
                <span className={css.block2blockName}>liquidity</span>
                <span className={css.block2BlockDescription}>Amount: 30 000 000</span>
            </div>
            <div className={css.block2block5} ref={block5Ref}>
                <span className={css.block2BlockPercent}>15%</span>
                <span className={css.block2blockName}>game rewards</span>
                <span className={css.block2BlockDescription}>Amount: 30 000 000</span>
            </div>

            {/* Mobile */}
            <div className={css.block2block1m}>
                <div className={css.mob}>
                    <span className={css.block2BlockPercent}>20%</span>
                    <span className={css.block2blockName}>marketing and community</span>
                </div>
                <span className={css.block2BlockDescription}>Amount: 30 000 000</span>
            </div> 
            <div className={css.block2block1m}>
                <div className={css.mob}>
                    <span className={css.block2BlockPercent}>40%</span>
                    <span className={css.block2blockName}>airdrop</span>
                </div>
                <span className={css.block2BlockDescription}>Amount: 30 000 000</span>
            </div> 
            <div className={css.block2block1m}>
                <div className={css.mob}>
                    <span className={css.block2BlockPercent}>5%</span>
                    <span className={css.block2blockName}>eam</span>
                </div>
                <span className={css.block2BlockDescription}>Amount: 30 000 000</span>
            </div> 
            <div className={css.block2block1m}>
                <div className={css.mob}>
                    <span className={css.block2BlockPercent}>10%</span>
                    <span className={css.block2blockName}>liquidity</span>
                </div>
                <span className={css.block2BlockDescription}>Amount: 30 000 000</span>
            </div> 
            <div className={css.block2block1m}>
                <div className={css.mob}>
                    <span className={css.block2BlockPercent}>15%</span>
                    <span className={css.block2blockName}>game rewards</span>
                </div>
                <span className={css.block2BlockDescription}>Amount: 30 000 000</span>
            </div> 


        </motion.div> {/* block2Container */}
        
        <motion.div viewport={{once:true,amount:.4}} initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1}} className={css.block3Container}>
            <div className={css.textConatiner}>
                <div className={css.modalFaq}>
                    <h3 className={css.text1}>FAQ</h3>
                </div> 
                <div className={css.modalTriggerContainer} onClick={()=>faqClick(faqState1,setFaqState1,faqRef1)}>
                    <h3 className={css.text2}>How do i buy and sell $TOSHI?</h3>
                    {faqState1?
                    <img src={triangle} className={css.triangle} style={{transform:"rotate(180deg)"}}/>
                    :
                    <img src={triangle} className={css.triangle} />
                    }
                    
                </div> 
                <motion.h3 ref={faqRef1}  initial={{opacity:0}} whileInView={{opacity:1}} className={css.text3}>
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                </motion.h3>

                {/* {faqState1&&
                        <motion.h3 style={{position:"relative",margin:"0 20px 0 0"}}  initial={{opacity:0}} whileInView={{opacity:1}} className={css.text3}>Yes, our first overview roadmap is available on medium</motion.h3>
                    } */}
                <div className={css.modalTriggerContainer}onClick={()=>faqClick(faqState2,setFaqState2,faqRef2)}>
                    <h3 className={css.text2}>Will Toshi list on more CEXs</h3>
                    {faqState2?
                    <img src={triangle} className={css.triangle} style={{transform:"rotate(180deg)"}}/>
                    :
                    <img src={triangle} className={css.triangle} />
                    }
                </div> 
                <motion.h3 ref={faqRef2}  initial={{opacity:0}} whileInView={{opacity:1}} className={css.text3}>
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                </motion.h3>
                <div className={css.modalTriggerContainer} onClick={()=>faqClick(faqState3,setFaqState3,faqRef3)}>
                    <h3 className={css.text2}>Is there a Toshi roadmap available?</h3>
                    {faqState3?
                    <img src={triangle} className={css.triangle} style={{transform:"rotate(180deg)"}}/>
                    :
                    <img src={triangle} className={css.triangle} />
                    }
                </div> 
                <motion.h3 ref={faqRef3}  initial={{opacity:0}} whileInView={{opacity:1}} className={css.text3}>
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                </motion.h3>
                <div className={css.modalTriggerContainer} onClick={()=>faqClick(faqState4,setFaqState4,faqRef4)}>
                    <h3 className={css.text2}>What are NFTOSHIS?</h3>
                    {faqState4?
                    <img src={triangle} className={css.triangle} style={{transform:"rotate(180deg)"}}/>
                    :
                    <img src={triangle} className={css.triangle} />
                    }
                </div> 
                <motion.h3 ref={faqRef4}  initial={{opacity:0}} whileInView={{opacity:1}} className={css.text3}>
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                Yes, our first overview roadmap is available on medium
                </motion.h3>
            </div> {/* textConatiner */}
            <div className={css.imgContainer}>
                <motion.img viewport={{once:true}} initial={{scale:.8}} whileInView={{scale:1}} transition={{duration:1}} src={cookies} className={css.img}/>
                
            </div>
        
        </motion.div>{/* block3Container */}
        {window.screen.width>768?
        <motion.img viewport={{once:true}} initial={{opacity:0,x:-150,y:50}} whileInView={{opacity:1,x:0,y:0}} transition={{duration:1}} src={cookie7} className={css.cookie7}/>:
        <motion.img viewport={{once:true}} initial={{opacity:0,x:-50,y:50}} whileInView={{opacity:1,x:0,y:0}} transition={{duration:1}} src={cookie7} className={css.cookie7}/>}
        {window.screen.width>768?
        <motion.img viewport={{once:true}} initial={{opacity:0,x:150,y:50}} whileInView={{opacity:1,x:0,y:0}} transition={{duration:1}} src={cookie8} className={css.cookie8}/>:
        <motion.img viewport={{once:true}} initial={{opacity:0,x:50,y:50}} whileInView={{opacity:1,x:0,y:0}} transition={{duration:1}} src={cookie8} className={css.cookie8}/>}
        <Footer/>
    </section>
    
</>
     );
})
 
export default Hero;