import css from './ModalConnectWallet.module.css'
import close from '../../img/Close1.png'
import mobx from '../../store/mobx'
import {observer} from 'mobx-react-lite'
import {motion} from 'framer-motion'
import cookie1 from '../../img/cookie1.png'
import iconModalCard from '../../img/iconModalCard.png'

const ModalConnectWallet = observer(() => {
    if(mobx.modalConnectWallet){
        return ( 
            <>
                <motion.section onClick={()=>mobx.setModalConnectWallet(false)}   initial={{opacity:0}} whileInView={{opacity:1}}  className={css.container}>     
                <div className={css.modalWind} onClick={e => e.stopPropagation()}>
                    <img src={cookie1} className={css.cookie}/>
                    <h2 className={css.header}>Connect wallet</h2>
                    <img  src={close} onClick={()=>mobx.setModalConnectWallet(false)} className={css.close}/>
                    <div className={css.cardContainer}>
                        <a href={mobx.connectArrow[mobx.binanceID].link1} className={css.card}>
                        <img className={css.icon} src={`assets/img/${mobx.connectArrow[mobx.binanceID].icon1}`} />
                            <span className={css.cardHeader}>{mobx.binanceArrow[mobx.binanceID].tnl1IName}</span>
                        </a>
                        <a href={mobx.connectArrow[mobx.binanceID].link2} className={css.card}>
                        <img className={css.icon} src={`assets/img/${mobx.connectArrow[mobx.binanceID].icon2}`} />
                            <span className={css.cardHeader}>{mobx.binanceArrow[mobx.binanceID].tnl2IName}</span>
                        </a>
                        <a href={mobx.connectArrow[mobx.binanceID].link3} className={css.card}>
                        <img className={css.icon} src={`assets/img/${mobx.connectArrow[mobx.binanceID].icon3}`} />
                            <span className={css.cardHeader}>{mobx.binanceArrow[mobx.binanceID].tnl3IName}</span>
                        </a>
                        <a href={mobx.connectArrow[mobx.binanceID].link4} className={css.card}>
                        <img className={css.icon} src={`assets/img/${mobx.connectArrow[mobx.binanceID].icon4}`} />
                            <span className={css.cardHeader}>{mobx.binanceArrow[mobx.binanceID].tnl4IName}</span>
                        </a>
                    </div>
                </div>
                
                </motion.section>
            
            </>
                 );
    }
   
})
 
export default ModalConnectWallet;