import {makeAutoObservable} from 'mobx'

class Mobx {
    constructor(){
        makeAutoObservable(this)
    }
    modal = false
    modalByToken = false
    modalConnectWallet = false
    count = 0
    group1 = false
    group2 = false
    group3 = false
    tapToStart = false
    starVisible = false
    binanceModalVisible = false
    binanceID = 1
    dollarVisibe = false

    setDollarVisible(bool){
        this.dollarVisibe = bool
    }

    setBinanceID(id){
        this.binanceID=id
    }
    binanceArrow =[
        {
            "id":0,
            "name":'BINANCE0',
            "block2text":"0xac1b2186ofc1b2186ofc1b2186ofc1b2186of",
            "block2Link":"0000000000000000",
            "tnl1":"01",
            "tnl1ICON":"iconModalCard.png",
            "tnl1IName":"01platform name",
            "tnl2":"02",
            "tnl2ICON":"iconModalCard.png",
            "tnl2IName":"02platform name",
            "tnl3":"03",
            "tnl3ICON":"iconModalCardW.png",
            "tnl3IName":"02platform name",
            "tnl4":"04",
            "tnl4ICON":"iconModalCard.png",
            "tnl4IName":"02platform name",
            "tnl5":"05",
            "tnl5ICON":"iconModalCard.png",
            "tnl5IName":"02platform name",
            "tnl6":"06",
            "tnl6ICON":"iconModalCard.png",
            "tnl6IName":"02platform name",
            "tnl7":"07",
            "tnl7ICON":"iconModalCard.png",
            "tnl7IName":"02platform name",
            "tnl8":"08",
            "tnl8ICON":"iconModalCardW.png",
            "tnl8IName":"02platform name",
        },
        {
            "id":1,
            "name":'BINANCE1',
            "block2text":"110xac1b2186ofc1b2186ofc1b2186ofc1b2186of",
            "block2Link":"111111111111111",
            "tnl1":"11",
            "tnl1ICON":"iconModalCard.png",
            "tnl1IName":"11platform name",
            "tnl2":"12",
            "tnl2ICON":"iconModalCard.png",
            "tnl2IName":"12platform name",
            "tnl3":"13",
            "tnl3ICON":"iconModalCard.png",
            "tnl3IName":"12platform name",
            "tnl4":"14",
            "tnl4ICON":"iconModalCardW.png",
            "tnl4IName":"12platform name",
            "tnl5":"15",
            "tnl5ICON":"iconModalCard.png",
            "tnl5IName":"12platform name",
            "tnl6":"16",
            "tnl6ICON":"iconModalCard.png",
            "tnl6IName":"12platform name",
            "tnl7":"17",
            "tnl7ICON":"iconModalCard.png",
            "tnl7IName":"12platform name",
            "tnl8":"18",
            "tnl8ICON":"iconModalCard.png",
            "tnl8IName":"12platform name",
        },
        {
            "id":2,
            "name":'BINANCE2',
            "block2text":"220xac1b2186ofc1b2186ofc1b2186ofc1b2186of",
            "block2Link":"222222222222222",
            "tnl1":"21",
            "tnl1ICON":"iconModalCard.png",
            "tnl1IName":"21platform name",
            "tnl2":"22",
            "tnl2ICON":"iconModalCard.png",
            "tnl2IName":"21platform name",
            "tnl3":"23",
            "tnl3ICON":"iconModalCardW.png",
            "tnl3IName":"21platform name",
            "tnl4":"24",
            "tnl4ICON":"iconModalCard.png",
            "tnl4IName":"21platform name",
            "tnl5":"25",
            "tnl5ICON":"iconModalCard.png",
            "tnl5IName":"21platform name",
            "tnl6":"26",
            "tnl6ICON":"iconModalCard.png",
            "tnl6IName":"21platform name",
            "tnl7":"27",
            "tnl7ICON":"iconModalCard.png",
            "tnl7IName":"21platform name",
            "tnl8":"28",
            "tnl8ICON":"iconModalCardW.png",
            "tnl8IName":"21platform name",
        },
        {
            "id":3,
            "name":'BINANCE3',
            "block2text":"330xac1b2186ofc1b2186ofc1b2186ofc1b2186of",
            "block2Link":"3333333333333333",
            "tnl1":"01",
            "tnl1ICON":"iconModalCardW.png",
            "tnl1IName":"01platform name",
            "tnl2":"02",
            "tnl2ICON":"iconModalCard.png",
            "tnl2IName":"platform name",
            "tnl3":"03",
            "tnl3ICON":"iconModalCard.png",
            "tnl3IName":"platform name",
            "tnl4":"04",
            "tnl4ICON":"iconModalCard.png",
            "tnl4IName":"platform name",
            "tnl5":"05",
            "tnl5ICON":"iconModalCardW.png",
            "tnl5IName":"platform name",
            "tnl6":"06",
            "tnl6ICON":"iconModalCard.png",
            "tnl6IName":"platform name",
            "tnl7":"07",
            "tnl7ICON":"iconModalCard.png",
            "tnl7IName":"platform name",
            "tnl8":"08",
            "tnl8ICON":"iconModalCard.png",
            "tnl8IName":"platform name",
        },
        {
            "id":4,
            "name":'BINANCE4',
            "block2text":"440xac1b2186ofc1b2186ofc1b2186ofc1b2186of",
            "block2Link":"444444444444444444",
            "tnl1":"01",
            "tnl1ICON":"iconModalCardW.png",
            "tnl1IName":"01platform name",
            "tnl2":"02",
            "tnl2ICON":"iconModalCard.png",
            "tnl2IName":"platform name",
            "tnl3":"03",
            "tnl3ICON":"iconModalCard.png",
            "tnl3IName":"platform name",
            "tnl4":"04",
            "tnl4ICON":"iconModalCardW.png",
            "tnl4IName":"platform name",
            "tnl5":"05",
            "tnl5ICON":"iconModalCardW.png",
            "tnl5IName":"platform name",
            "tnl6":"06",
            "tnl6ICON":"iconModalCard.png",
            "tnl6IName":"platform name",
            "tnl7":"07",
            "tnl7ICON":"iconModalCard.png",
            "tnl7IName":"platform name",
            "tnl8":"08",
            "tnl8ICON":"iconModalCard.png",
            "tnl8IName":"platform name",
        },
        {
            "id":5,
            "name":'BINANCE5',
            "block2text":"550xac1b2186ofc1b2186ofc1b2186ofc1b2186of",
            "block2Link":"555555555555555555555",
            "tnl1":"01",
            "tnl1ICON":"iconModalCard.png",
            "tnl1IName":"01platform name",
            "tnl2":"02",
            "tnl2ICON":"iconModalCard.png",
            "tnl2IName":"platform name",
            "tnl3":"03",
            "tnl3ICON":"iconModalCard.png",
            "tnl3IName":"platform name",
            "tnl4":"04",
            "tnl4ICON":"iconModalCard.png",
            "tnl4IName":"platform name",
            "tnl5":"05",
            "tnl5ICON":"iconModalCard.png",
            "tnl5IName":"platform name",
            "tnl6":"06",
            "tnl6ICON":"iconModalCard.png",
            "tnl6IName":"platform name",
            "tnl7":"07",
            "tnl7ICON":"iconModalCardW.png",
            "tnl7IName":"platform name",
            "tnl8":"08",
            "tnl8ICON":"iconModalCardW.png",
            "tnl8IName":"platform name",
        },
        {
            "id":6,
            "name":'BINANCE6',
            "block2text":"660xac1b2186ofc1b2186ofc1b2186ofc1b2186of",
            "block2Link":"66666666666666666666666",
            "tnl1":"01",
            "tnl1ICON":"iconModalCard.png",
            "tnl1IName":"01platform name",
            "tnl2":"02",
            "tnl2ICON":"iconModalCard.png",
            "tnl2IName":"platform name",
            "tnl3":"03",
            "tnl3ICON":"iconModalCard.png",
            "tnl3IName":"platform name",
            "tnl4":"04",
            "tnl4ICON":"iconModalCardW.png",
            "tnl4IName":"platform name",
            "tnl5":"05",
            "tnl5ICON":"iconModalCardW.png",
            "tnl5IName":"platform name",
            "tnl6":"06",
            "tnl6ICON":"iconModalCardW.png",
            "tnl6IName":"platform name",
            "tnl7":"07",
            "tnl7ICON":"iconModalCard.png",
            "tnl7IName":"platform name",
            "tnl8":"08",
            "tnl8ICON":"iconModalCard.png",
            "tnl8IName":"platform name",
        },
        {
            "id":7,
            "name":'BINANCE7',
            "block2text":"770xac1b2186ofc1b2186ofc1b2186ofc1b2186of",
            "block2Link":"7777777777777777777",
            "tnl1":"01",
            "tnl1ICON":"iconModalCardW.png",
            "tnl1IName":"01platform name",
            "tnl2":"02",
            "tnl2ICON":"iconModalCardW.png",
            "tnl2IName":"platform name",
            "tnl3":"03",
            "tnl3ICON":"iconModalCardW.png",
            "tnl3IName":"platform name",
            "tnl4":"04",
            "tnl4ICON":"iconModalCard.png",
            "tnl4IName":"platform name",
            "tnl5":"05",
            "tnl5ICON":"iconModalCard.png",
            "tnl5IName":"platform name",
            "tnl6":"06",
            "tnl6ICON":"iconModalCard.png",
            "tnl6IName":"platform name",
            "tnl7":"07",
            "tnl7ICON":"iconModalCard.png",
            "tnl7IName":"platform name",
            "tnl8":"08",
            "tnl8ICON":"iconModalCard.png",
            "tnl8IName":"platform name",
        },
       

        
    ]
    connectArrow =[
        {
            "id":0,
            "name1":'01platform name',
            "link1":"0000000000",
            "icon1":"iconModalCard.png",
            "name2":'02platform name',
            "link2":"0000000000",
            "icon2":"iconModalCard.png",
            "name3":'03platform name',
            "link3":"0000000000",
            "icon3":"iconModalCard.png",
            "name4":'04platform name',
            "link4":"0000000000",
            "icon4":"iconModalCard.png",
        },
        {
            "id":1,
            "name1":'11platform name',
            "link1":"0000000000",
            "icon1":"iconModalCardW.png",
            "name2":'12platform name',
            "link2":"0000000000",
            "icon2":"iconModalCard.png",
            "name3":'13platform name',
            "link3":"0000000000",
            "icon3":"iconModalCard.png",
            "name4":'14platform name',
            "link4":"0000000000",
            "icon4":"iconModalCard.png",
        },
        {
            "id":2,
            "name1":'21platform name',
            "link1":"0000000000",
            "icon1":"iconModalCardW.png",
            "name2":'22platform name',
            "link2":"0000000000",
            "icon2":"iconModalCard.png",
            "name3":'23platform name',
            "link3":"0000000000",
            "icon3":"iconModalCard.png",
            "name4":'24platform name',
            "link4":"0000000000",
            "icon4":"iconModalCardW.png",
        },
        {
            "id":3,
            "name1":'31platform name',
            "link1":"0000000000",
            "icon1":"iconModalCard.png",
            "name2":'32platform name',
            "link2":"0000000000",
            "icon2":"iconModalCardW.png",
            "name3":'33platform name',
            "link3":"0000000000",
            "icon3":"iconModalCard.png",
            "name4":'34platform name',
            "link4":"0000000000",
            "icon4":"iconModalCard.png",
        },
        {
            "id":4,
            "name1":'41platform name',
            "link1":"0000000000",
            "icon1":"iconModalCard.png",
            "name2":'42platform name',
            "link2":"0000000000",
            "icon2":"iconModalCard.png",
            "name3":'43platform name',
            "link3":"0000000000",
            "icon3":"iconModalCard.png",
            "name4":'44platform name',
            "link4":"0000000000",
            "icon4":"iconModalCardW.png",
        },
        {
            "id":5,
            "name1":'51platform name',
            "link1":"0000000000",
            "icon1":"iconModalCardW.png",
            "name2":'52platform name',
            "link2":"0000000000",
            "icon2":"iconModalCard.png",
            "name3":'53platform name',
            "link3":"0000000000",
            "icon3":"iconModalCard.png",
            "name4":'54platform name',
            "link4":"0000000000",
            "icon4":"iconModalCardW.png",
        },
        {
            "id":6,
            "name1":'61platform name',
            "link1":"0000000000",
            "icon1":"iconModalCard.png",
            "name2":'62platform name',
            "link2":"0000000000",
            "icon2":"iconModalCardW.png",
            "name3":'63platform name',
            "link3":"0000000000",
            "icon3":"iconModalCardW.png",
            "name4":'64platform name',
            "link4":"0000000000",
            "icon4":"iconModalCard.png",
        },
        {
            "id":7,
            "name1":'71platform name',
            "link1":"0000000000",
            "icon1":"iconModalCard.png",
            "name2":'72platform name',
            "link2":"0000000000",
            "icon2":"iconModalCard.png",
            "name3":'73platform name',
            "link3":"0000000000",
            "icon3":"iconModalCardW.png",
            "name4":'74platform name',
            "link4":"0000000000",
            "icon4":"iconModalCard.png",
        },
        
    ]
    setBinanceModalVisible(bool){
        this.binanceModalVisible = bool
    }
    setStarVisible(bool){
        this.starVisible = bool
    }
    setTapToStart(bool){
    this.tapToStart = bool
    }
    setModal(bool){
    this.modal = bool
    }
    setModalByToken(bool){
    this.modalByToken = bool
    }
    setModalConnectWallet(bool){
    this.modalConnectWallet = bool
    }
    setCount(newCount){
        this.count = newCount
    }
    setGrop1(){
        this.group1=true
    }
    setGrop2(){
        this.group2=true
    }
    setGrop3(){
        this.group3=true
    }
}

export default new Mobx();